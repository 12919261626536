import agent from '@/api/agent';
// Импортирай agent
import type {
    EditUserProfile,
    NewUserFormValues,
    User,
    UserFormValues,
    UserUpdateFormValues,
} from '@/models/user';
import router from '@/router';

// Импортирай типизацията за User и UserFormValues
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import { useRouter } from 'vue-router';

interface UserState {
    user: User | null;
    users: Map<string, User> | null;
    isAuthenticated: boolean;
}

export const useUserStore = defineStore('user', {
    state: (): UserState => ({
        user: null,
        users: new Map(),
        isAuthenticated: false,
    }),
    getters: {
        usersArray: state => {
            return Array.from(state.users?.values() || []);
        },
    },
    actions: {
        async hydrate() {
            await this.setUser();
        },

        async setUser() {
            try {
                const token = Cookies.get('token');
                if (token) {
                    const decodedUser = jwtDecode<User>(token);
                    this.user = decodedUser;
                    this.isAuthenticated = true;
                }
            } catch (error) {
                await this.logout();
                console.error('Error fetching user:', error);
            }
        },

        async login(userData: UserFormValues, router: ReturnType<typeof useRouter>) {
            try {
                const user = (await agent.Users.login(userData)).data;
                const token = user.token;

                if (token) {
                    const decodedUser = jwtDecode<User>(token);

                    Cookies.set('token', token, {
                        expires: new Date(decodedUser.exp * 1000),
                        path: '/',
                        sameSite: 'Strict',
                    });
                    this.user = decodedUser;
                    this.isAuthenticated = true;
                    router.push('/');
                } else {
                    this.isAuthenticated = false;
                }
            } catch (error: any) {
                throw new Error(error.data.error);
            }
        },

        async logout() {
            console.log('Logging out');

            Cookies.remove('token');
            this.user = null;
            this.isAuthenticated = false;
            router.push('/login');
        },

        async getAllUsers() {
            try {
                if (!this.users || this.users.size === 0) {
                    const users = (await agent.Users.getAllUsers()).data;
                    this.users = new Map(users.map(user => [user.id, user]));
                }
                return this.users;
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },

        async createUser(userData: NewUserFormValues) {
            try {
                if (!userData) {
                    throw new Error('Всички полета са задължителни');
                }

                const response = await agent.Users.createUser(userData);
                if (response.status !== 200) {
                    throw new Error((response.data as { error: string }).error);
                }

                if (this.users === null) {
                    this.users = new Map();
                }

                this.users.set((response.data as User).id, response.data as User);
            } catch (error: any) {
                throw new Error(error?.data?.error);
            }
        },

        async updateUser(updatedUser: UserUpdateFormValues) {
            try {
                const response = await agent.Users.updateUser(updatedUser);
                if (response.status !== 200) {
                    throw new Error('Неуспешно обновяване на профила');
                }

                if (this.users) {
                    this.users.set(response.data.id, response.data);
                }
            } catch (error) {
                console.error('Error updating profile:', error);
                toast.error(error);
            }
        },

        async updateUserProfile(updatedUser: EditUserProfile) {
            try {
                const user = (await agent.Users.updateUserProfile(updatedUser)).data;
                const token = user.token;

                if (token) {
                    Cookies.set('token', token, {
                        path: '/',
                        sameSite: 'Strict',
                    });
                    const decodedUser = jwtDecode<User>(token);

                    this.user = decodedUser;
                    this.isAuthenticated = true;
                }
            } catch (error: any) {
                throw error.data.error;
            }
        },

        async deleteProfile(userId: string) {
            try {
                (await agent.Users.deleteUser(userId)).data;
                if (this.users) {
                    const user = this.users.get(userId);
                    user!.isDeleted = true;
                    this.users.set(userId, user!);
                }
            } catch (error) {
                console.error('Error deleting profile:', error);
            }
        },
    },
});
