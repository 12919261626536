/* eslint-disable no-underscore-dangle */
import { makeElement } from './helper';
import * as Tunes from './tunes';

export default class Alignment {
  /**
   * Default alignment
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_ALIGNMENT() {
    return 'left';
  }

  static get isTune() {
    return true;
  }

  getAlignment() {
    if (!!this.settings?.blocks && Object.prototype.hasOwnProperty.call(this.settings.blocks, this.block.name)) {
      return this.settings.blocks[this.block.name];
    }
    if (this.settings?.default) {
      return this.settings.default;
    }
    return Alignment.DEFAULT_ALIGNMENT;
  }

  /**
   *
   * @param api
   * @param data 既に設定されているデータ
   * @param settings tuneに設定項目
   * @param block tuneに設定されてるblock
   */
  constructor({
    api, data, config, block,
  }) {
    this.api = api;
    this.block = block;
    /**
         config:{
            default: "right",
            blocks: {
              header: 'center',
              list: 'right'
            }
          },
         */
    this.settings = config;
    this.data = data || { alignment: this.getAlignment() };
    this.alignmentSettings = [
      {
        title: 'Align to left',
        name: 'left',
        icon: '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.69043 4H9.3571M2.69043 8H14.6904M2.69043 12H12.0238" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      },
      {
        title: 'Align to center',
        name: 'center',
        icon: '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.3571 4H12.0238M2.69043 8H14.6904M4.02376 12H13.3571" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      },
      {
        title: 'Align to right',
        name: 'right',
        icon: '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.02376 4H14.6904M2.69043 8H14.6904M5.3571 12H14.6904" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      },
    ];

    /**
     * Styles
     *
     * @private
     */
    this._CSS = {
      alignment: {
        left: 'ce-tune-alignment--left',
        center: 'ce-tune-alignment--center',
        right: 'ce-tune-alignment--right',
      },
      active: 'ce-popover-item--active',
    };

    /**
     * HTML nodes
     *
     * @private
     */
    this._buttons = {
      wrapper: null,
      elements: [],
    };
  }

  /**
   * block自体をwrapしてくれる
   * constructorで与えられたalignmentを代入しようとすると、holderが確定してなく
   * renderでやろうとすると、tuneを表示時に処理が走る
   * @param blockContent
   */
  wrap(blockContent) {
    this.wrapper = makeElement('div');
    this.wrapper.classList.toggle(this._CSS.alignment[this.data.alignment]);
    this.wrapper.append(blockContent);
    return this.wrapper;
  }

  /**
   * rendering block tune
   * @returns {*}
   */
  render() {
    setTimeout(() => {
      const styleSection = Tunes.getStyleSection();

      this._buttons.wrapper = styleSection;

      this.alignmentSettings.map((align) => {
        const button = document.createElement('div');
        button.classList.add(this.api.styles.settingsButton, 'ce-popover-item');
        button.innerHTML = `<div class="ce-popover-item__icon">${align.icon}</div><div class="ce-popover-item__title">${align.title}</div>`;
        button.classList.toggle(this._CSS.active, align.name === this.data.alignment);

        styleSection.appendChild(button);
        this._buttons.elements.push(button);

        return button;
      }).forEach((element, index, elements) => {
        this.api.listeners.on(element, 'click', this.alignItemsHandler.bind(this, index, elements));
      });

      const customContent = document.querySelector('.ce-popover__custom-content');
      if (customContent && !customContent.querySelector('.ce-settings__style-tunes')) {
        customContent.appendChild(styleSection);
      }
    }, 10);

    return [];
  }

  /**
   * Handles the alignment of elements based on the selected index.
   *
   * @param {number} index - The index of the alignment settings to use.
   * @param {Element[]} elements - An array of elements to align.
   * @param {Event} [event] - The event triggering the alignment.
   *
   * @return {void}
   */
  // eslint-disable-next-line no-unused-vars
  alignItemsHandler(index, elements, event) {
    this.data = {
      alignment: this.alignmentSettings[index].name,
    };

    elements.forEach((el, i) => {
      const { name } = this.alignmentSettings[i];
      el.classList.toggle(this._CSS.active, name === this.data.alignment);
      // toggle alignment style class for block
      this.wrapper.classList.toggle(this._CSS.alignment[name], name === this.data.alignment);
    });

    setTimeout(() => {
      const changeEvent = new Event('contentChange');
      document.dispatchEvent(changeEvent);
    }, 200);

    this.api.toolbar.close();
  }

  /**
   * save
   * @returns {*}
   */
  save() {
    return this.data;
  }

  /**
   * Detaches event listeners associated with the destroy method.
   *
   * @method destroy
   *
   * @returns {void} The method does not have a return value.
   */
  destroy() {
    this._buttons.elements.forEach((el) => {
      this.api.listeners.off(el, 'click', this.alignItemsHandler);
    });
  }
}
