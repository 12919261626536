import { createApp } from 'vue';

import '@/scss/style.scss';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import { router } from './router';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { createPinia } from 'pinia';
import VueApexCharts from 'vue3-apexcharts';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueTablerIcons from 'vue-tabler-icons';

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(VueTablerIcons);
app.use(VueApexCharts);
app.use(pinia);
app.use(Vue3Toastify, {
    position: 'top-right',
    duration: 3000,
} as ToastContainerOptions);
app.use(vuetify).mount('#app');
