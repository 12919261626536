import { computed, reactive, ref } from 'vue';



import agent from '@/api/agent';
import type { CategoryModel, CategoryUpdateFormValues, NewCategoryFormValues } from '@/models/category';
import Pagination, { PagingParams } from '@/models/pagination';

import { useUserStore } from './userStore';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

export const useCategoryStore = defineStore('category', () => {
  const userStore = useUserStore();
  const categories = reactive<Map<number, CategoryModel>>(new Map());
  const pagedCategories = reactive<Map<number, CategoryModel>>(new Map());

  const category = ref<CategoryModel | null>(null);

  const searchQuery = ref('');

  const predicate = ref(new Map().set('all', true));

  const pagination = ref({} as Pagination);
  const pagingParams = ref<PagingParams>(new PagingParams());

  const axiosParams = computed(() => {
    const params = new URLSearchParams();
    predicate.value.forEach((value, key) => {
      params.append(key, value.toString());
    });
    return params;
  });

  function setPagingParams(pagingPar: PagingParams) {
    return (pagingParams.value = pagingPar);
  }

  function setPagination(pagi: Pagination) {
    return (pagination.value = pagi);
  }

  function setPredicate(predicateKey: string, value: string | number | number[] | null) {
    const resetPredicate = () => {
      predicate.value.forEach((_, key) => {
        searchQuery.value = '';
        predicate.value.delete(key);
      });
    };

    switch (predicateKey) {
      case 'all':
        resetPredicate();
        predicate.value.set('all', true);
        break;
      case 'search':
        resetPredicate();
        searchQuery.value = value as string;
        categories.clear();
        predicate.value.set('search', value);
        break;
    }
  }

  const groupedCategoriesArray = computed(() => {
    const flattenCategories = (category: CategoryModel): CategoryModel[] => {
      const children = category.children ? category.children.flatMap(flattenCategories) : [];
      return [category, ...children];
    };

    const isAdmin = userStore.user?.role?.name === 'administrator' || userStore.user?.role?.name === 'master_admin';

    // Flatten всички категории, за да включим и децата
    const allCategories = Array.from(categories.values() || []).flatMap(flattenCategories);

    if (isAdmin) {
      return allCategories;
    }

    const userCategories = allCategories.filter(category => userStore.user?.categories?.some(id => id === category.id));

    return userCategories;
  });

  const categoriesArray = computed(() => {
    return Array.from(categories.values());
  });

  const pagedCategoriesArray = computed(() => {
    return Array.from(pagedCategories.values());
  });

  async function setCategoriesOrder(categories: CategoryModel[]) {
    try {
      const categoryIds = categories.map(category => category.id);
      await agent.Categories.setCategoriesOrder(categoryIds);
    } catch (error) {
      console.error('Error setting categories order:', error);
      toast.error('Грешка при настройване на реда на категориите');
    }
  }

  async function getAllCategories() {
    try {
      const response = (await agent.Categories.getAllCategories(axiosParams.value)).data;
      response.forEach(cat => {
        categories.set(cat.id, cat);
      });
      return categories;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Грешка при зареждане на категориите');
    }
  }

  async function listCategory() {
    try {
      pagedCategories.clear();
      const response = (await agent.Categories.getPagedCategory(axiosParams.value)).data;

      response.data.forEach(cat => {
        if (cat.parentId === 0) {
          cat.parent = {
            id: 0,
            bgname: 'Главна категория',
            enname: 'Main category',
            isDeleted: false,
            parentId: 0,
            children: [],
          };
        }
        pagedCategories.set(cat.id, cat);
      });
      setPagination(response.pagination);
      return categories;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Грешка при зареждане на категориите');
    }
  }
  async function getCategory(id: number) {
    try {
      const response = (await agent.Categories.getCategory(id)).data;
      if (!response) {
      }
      category.value = response;
      return category.value;
    } catch (error) {
      throw new Error('Грешка при зареждане на категорията');
    }
  }

  async function createCategory(cat: NewCategoryFormValues) {
    try {
      const response = (await agent.Categories.addCategory(cat)).data;
      pagedCategories.set(response.id, response);
    } catch (error) {
      console.error('Error creating category:', error);
      toast.error('Грешка при създаване на категорията');
    }
  }

  async function deleteCategory(id: number) {
    try {
      await agent.Categories.deleteCategory(id);
      toast.success('Категорията е изтрита успешно');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Грешка при изтриване на категорията');
    }
  }

  async function searchCategories() {
    try {
      pagedCategories.clear();
      const response = (await agent.Categories.searchCategories(axiosParams.value)).data;
      response.forEach(cat => {
        pagedCategories.set(cat.id, cat);
      });
    } catch (error) {
      console.error('Error searching categories:', error);
      toast.error('Грешка при търсене на категории');
    }
  }

  async function updateCategory(cat: CategoryUpdateFormValues) {
    try {
      const response = (await agent.Categories.updateCategory(cat)).data;
      if (searchQuery.value) {
        await searchCategories();
      } else {
        categories.set(response.id, response);
        pagedCategories.set(response.id, response);
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
  }

  async function updateCategoryHierarchy(categories: number[], newParentId: number) {
    try {
      await agent.Categories.updateCategoryHierarchy(categories, newParentId);
      getAllCategories();
    } catch (error) {
      console.error('Error updating category hierarchy:', error);
    }
  }

  return {
    categories,
    category,
    groupedCategoriesArray,
    categoriesArray,
    getAllCategories,
    getCategory,
    createCategory,
    deleteCategory,
    updateCategory,
    updateCategoryHierarchy,
    setPredicate,
    setPagination,
    setPagingParams,
    pagination,
    pagingParams,
    listCategory,
    pagedCategoriesArray,
    searchCategories,
    searchQuery,
    setCategoriesOrder,
  };
});