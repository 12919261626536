import type { Article, ArticleData } from '@/models/article';
import type { Banner } from '@/models/banner';
import type {
    CategoryModel,
    CategoryUpdateFormValues,
    NewCategoryFormValues,
} from '@/models/category';
import { Department, DepartmentForm } from '@/models/department';
import { Employee, EmployeeForm } from '@/models/employee';
import { Event, EventForm } from '@/models/events';
import { PaginatedResult } from '@/models/pagination';
import type {
    EditUserProfile,
    NewUserFormValues,
    Role,
    User,
    UserFormValues,
    UserUpdateFormValues,
} from '@/models/user';

import axios, { type AxiosError, type AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'vue3-toastify';

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

interface ApiResponse<T> {
    data: T;
    status: number;
}

const responseBody = <T>(response: AxiosResponse<T>): ApiResponse<T> => ({
    data: response.data,
    status: response.status,
});

axios.interceptors.request.use(config => {
    const token = Cookies.get('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});
export const setupAxiosInterceptors = (onUnauthorized: () => void) => {
    axios.interceptors.response.use(
        async response => {
            const pagination = response.headers['pagination'];
            if (pagination) {
                response.data = new PaginatedResult(response.data, JSON.parse(pagination));
                return response as AxiosResponse<PaginatedResult<unknown>>;
            }
            return response;
        },
        (error: AxiosError) => {
            const { data, status, config, headers } = error.response as AxiosResponse;
            switch (status) {
                case 401:
                    if (
                        status === 401 &&
                        headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')
                    ) {
                        onUnauthorized();
                    }
                    console.log('Unauthorized');
                    toast.error('Неоторизиран достъп!');
                    break;
                case 403:
                    console.log('Forbidden');
                    break;
                case 404:
                    console.log('Not found');
                    break;
                case 500:
                    console.log('Server error');
                    break;
            }
            return Promise.reject({ data, status });
        },
    );
};

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Users = {
    login: (user: UserFormValues) => requests.post<User>('/users/login', user),
    getAllUsers: () => requests.get<User[]>('/users'),
    createUser: (user: NewUserFormValues) => requests.post('/users', user),
    updateUser: (user: UserUpdateFormValues) => requests.patch<User>(`users/${user.id}`, user),
    updateUserProfile: (user: EditUserProfile) => requests.post<User>('/users/profile', user),
    deleteUser: (id: string) => requests.delete<void>(`users/${id}`),
};

const Roles = {
    getAllRoles: () => requests.get<Role[]>('/roles'),
    addRole: (role: Role) => requests.post<Role>('/roles', role),
};

const Articles = {
    getAllArticles: (params: URLSearchParams) =>
        axios.get<PaginatedResult<ArticleData[]>>('/articles', { params }),
    getArticle: (id: string) => requests.get<ArticleData>(`/articles/info/${id}`),
    createArticle: (article: FormData) =>
        axios
            .post<Article>('/articles', article, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(responseBody),
    updateArticle: (article: FormData, id: string) =>
        axios
            .post<ArticleData>(`/articles/${id}`, article, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(responseBody),
    deleteArticle: (id: string) => requests.delete<void>(`articles/${id}`),
    uploadFile: (file: FormData) =>
        axios
            .post('/articles/upload-file', file, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(responseBody),
    setArticlesOrder(categoryId: number, articleIds: string[]) {
        return requests.patch<void>(`/articles/order`, { categoryId, articleIds });
    },
};

const Categories = {
    getAllCategories: (params: URLSearchParams) =>
        axios.get<CategoryModel[]>('/categories', { params }),
    getCategory: (id: number) => requests.get<CategoryModel>(`categories/${id}`),
    addCategory: (category: NewCategoryFormValues) =>
        requests.post<CategoryModel>('/categories', category),
    deleteCategory: (id: number) => requests.delete<void>(`categories/${id}`),
    updateCategory: (category: CategoryUpdateFormValues) =>
        requests.patch<CategoryModel>(`categories/${category.id}`, category),
    updateCategoryHierarchy: (categories: number[], newParentId: number) =>
        requests.patch<void>('/categories/hierarchy', {
            categories,
            newParentId,
        }),
    getPagedCategory: (params: URLSearchParams) =>
        axios.get<PaginatedResult<CategoryModel[]>>('/categories/paged', {
            params,
        }),
    searchCategories: (params: URLSearchParams) =>
        axios.get<CategoryModel[]>('/categories/search', { params }),
    setCategoriesOrder: (categories: number[]) =>
        requests.patch<void>('/categories/order', { categories }),
    getCategoryArticles: (id: number) => requests.get<ArticleData[]>(`categories/${id}/articles`),
};

const Banners = {
    getAllBanners: (params: URLSearchParams) =>
        axios.get<PaginatedResult<Banner[]>>('/banners', { params }),
    getBanner: (id: string) => requests.get<Banner>(`banners/${id}`),
    createBanner: (banner: FormData) => requests.post<Banner>('/banners', banner),
    deleteBanner: (id: string) => requests.delete<Banner>(`banners/delete/${id}`),
    statusChange: (id: string, status: string) =>
        requests.patch<Banner>(`banners/status/${id}`, { status }),
    updateBanner: (id: string, banner: FormData) =>
        requests.patch<Banner>(`banners/update/${id}`, banner),
    getIndexes: () =>
        requests.get<{
            main: number[];
            content: number[];
        }>('/banners/indexes'),
};

const Images = {
    uploadImage: (image: FormData) =>
        axios
            .post('/articles/upload-image', image, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(responseBody),
    deleteImage: (title: any) => requests.post<void>('articles/delete-image', { title }),
};

const Departments = {
    getAllDepartments: (params: URLSearchParams) =>
        axios.get<PaginatedResult<Department[]>>('/departments', { params }),
    getDepartment: (id: string) => requests.get<Department>(`/departments/${id}`),
    createDepartment: (department: DepartmentForm) => requests.post('/departments', department),
    updateDepartment: (department: DepartmentForm) =>
        requests.post(`/departments/${department.id}`, department),
    deleteDepartment: (id: string) => requests.delete<void>(`/departments/${id}`),
    list: () => requests.get<Department[]>('/departments/list'),
};

const Employees = {
    list: (params: URLSearchParams) =>
        axios.get<PaginatedResult<Employee[]>>('/employees', { params }),
    getEmployee: (id: string) => requests.get<Employee>(`/employees/${id}`),
    createEmployee: (employee: EmployeeForm) => requests.post('/employees', employee),
    updateEmployee: (employee: EmployeeForm) =>
        requests.post<Employee>(`/employees/${employee.id}`, employee),
    deleteEmployee: (id: string) => requests.delete<void>(`/employees/${id}`),
};

const Events = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Event[]>>('/events', { params }),
    getEvent: (id: string) => requests.get<Event>(`/events/${id}`),
    createEvent: (event: EventForm) => requests.post('/events', event),
    updateEvent: (event: EventForm) => requests.post<Event>(`/events/${event.id}`, event),
    deleteEvent: (id: string) => requests.delete<void>(`/events/${id}`),
};

const agent = {
    Users,
    Roles,
    Articles,
    Categories,
    Banners,
    Images,
    Departments,
    Employees,
    Events,
};

export default agent;
