import Table from '@editorjs/table'

export default class TablePlugin extends Table {
  static get sanitize() {
    return {
      b: true,
      a: {
        href: true,
      },
      i: true,
      br: {},
    };
  }
}