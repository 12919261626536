import * as $ from './dom';

/**
 * Retrieves or creates a section element with the provided class.
 *
 * @param {string} sectionClass - The class name of the section element to retrieve or create.
 * @return {Element} - The section element.
 */
function getSection(sectionClass) {
  let styleSectionEl = document.querySelector(`.${sectionClass}`);
  if (!styleSectionEl) {
    styleSectionEl = document.createElement('div');
    styleSectionEl.classList.add(sectionClass);
  }

  return styleSectionEl;
}

/**
 * Returns the style section element.
 *
 * @return {Element} The style section element.
 */
export function getStyleSection() {
  return getSection('ce-settings__style-tunes');
}

export function getTypeSection() {
  return getSection('ce-settings__type-tunes');
}

export function createTuneButton(api, { isActive, icon, label }) {
  const button = $.make('div', [api.styles.settingsButton, 'ce-popover-item', isActive ? 'ce-popover-item--active' : null]);
  const iconEl = $.make('div', 'ce-popover-item__icon', {}, icon);
  const titleEl = $.make('div', 'ce-popover-item__title', {}, label);
  button.appendChild(iconEl);
  button.appendChild(titleEl);
  button.title = label;

  return button;
}
