export const config = {
  baseUrl: import.meta.env.VITE_COMMON_CLIENT_URL || 'http://localhost:3000',
  imagesBasePath: 'common/images',
  fileBasePath: 'common/pdf',
};
const ALLOWED_MEDIA_FORMATS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.webp',
  '.svg',
  '.mp4',
  '.webm',
  '.mov',
  '.avi',
];

const IMAGES_IN_PDF_FOLDER = [
  '1347631428-iskane-001.jpg',
];

export function getImageUrl(filename: string): string {
  const baseServerUrl = config.baseUrl.replace(/\/api\/?$/, '');
  const extension = filename.substring(filename.lastIndexOf('.')).toLowerCase();

  let basePath = ALLOWED_MEDIA_FORMATS.includes(extension)
    ? config.imagesBasePath
    : config.fileBasePath;

  if (IMAGES_IN_PDF_FOLDER.includes(filename)) {
    basePath = config.fileBasePath;
  }

  return `${baseServerUrl}/${basePath}/${filename}`;
}
