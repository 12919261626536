export default class AnyButton {
    /**
     *
     * @returns {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            title: "Button",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <rect x="9" y="9" width="18" height="18" rx="3" fill="black"/>
  <path d="M25 16.2966H20.8875C20.8583 15.9861 20.7806 15.7044 20.6542 15.4515C20.5326 15.1987 20.3625 14.9813 20.1438 14.7994C19.9299 14.6131 19.6698 14.4712 19.3635 14.3736C19.0573 14.2715 18.7097 14.2205 18.3208 14.2205C17.6403 14.2205 17.0642 14.3714 16.5927 14.673C16.126 14.9747 15.7712 15.4072 15.5281 15.9705C15.2899 16.5339 15.1708 17.2104 15.1708 18C15.1708 18.834 15.2924 19.5326 15.5354 20.096C15.7833 20.6549 16.1406 21.0764 16.6073 21.3603C17.074 21.6397 17.6354 21.7795 18.2917 21.7795C18.666 21.7795 19.0014 21.7373 19.2979 21.653C19.5944 21.5643 19.8521 21.4379 20.0708 21.2738C20.2896 21.1096 20.467 20.9122 20.6031 20.6816C20.7441 20.4465 20.8389 20.1825 20.8875 19.8897L25 19.9163C24.9514 20.493 24.774 21.0808 24.4677 21.6797C24.1615 22.2741 23.7264 22.8241 23.1625 23.3298C22.6035 23.8311 21.9108 24.2348 21.0844 24.5409C20.258 24.847 19.2979 25 18.2042 25C16.8333 25 15.6035 24.7316 14.5146 24.1949C13.4306 23.6581 12.5726 22.8685 11.9406 21.826C11.3135 20.7836 11 19.5082 11 18C11 16.4829 11.3208 15.2053 11.9625 14.1673C12.6042 13.1248 13.4694 12.3375 14.5583 11.8051C15.6472 11.2684 16.8625 11 18.2042 11C19.1472 11 20.0149 11.1176 20.8073 11.3527C21.5997 11.5878 22.2948 11.9316 22.8927 12.384C23.4906 12.8321 23.9719 13.3843 24.3365 14.0409C24.701 14.6974 24.9222 15.4493 25 16.2966Z" fill="white"/>
</svg>`
        }
    }

    static get tunes() {
        return [
            {
                name: 'edit_mode',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <rect x="9" y="9" width="18" height="18" rx="3" fill="black"/>
  <path d="M25 16.2966H20.8875C20.8583 15.9861 20.7806 15.7044 20.6542 15.4515C20.5326 15.1987 20.3625 14.9813 20.1438 14.7994C19.9299 14.6131 19.6698 14.4712 19.3635 14.3736C19.0573 14.2715 18.7097 14.2205 18.3208 14.2205C17.6403 14.2205 17.0642 14.3714 16.5927 14.673C16.126 14.9747 15.7712 15.4072 15.5281 15.9705C15.2899 16.5339 15.1708 17.2104 15.1708 18C15.1708 18.834 15.2924 19.5326 15.5354 20.096C15.7833 20.6549 16.1406 21.0764 16.6073 21.3603C17.074 21.6397 17.6354 21.7795 18.2917 21.7795C18.666 21.7795 19.0014 21.7373 19.2979 21.653C19.5944 21.5643 19.8521 21.4379 20.0708 21.2738C20.2896 21.1096 20.467 20.9122 20.6031 20.6816C20.7441 20.4465 20.8389 20.1825 20.8875 19.8897L25 19.9163C24.9514 20.493 24.774 21.0808 24.4677 21.6797C24.1615 22.2741 23.7264 22.8241 23.1625 23.3298C22.6035 23.8311 21.9108 24.2348 21.0844 24.5409C20.258 24.847 19.2979 25 18.2042 25C16.8333 25 15.6035 24.7316 14.5146 24.1949C13.4306 23.6581 12.5726 22.8685 11.9406 21.826C11.3135 20.7836 11 19.5082 11 18C11 16.4829 11.3208 15.2053 11.9625 14.1673C12.6042 13.1248 13.4694 12.3375 14.5583 11.8051C15.6472 11.2684 16.8625 11 18.2042 11C19.1472 11 20.0149 11.1176 20.8073 11.3527C21.5997 11.5878 22.2948 11.9316 22.8927 12.384C23.4906 12.8321 23.9719 13.3843 24.3365 14.0409C24.701 14.6974 24.9222 15.4493 25 16.2966Z" fill="white"/>
</svg>`,
                title: 'Редактирай бутона',
            }
        ]
    }
    renderSettings() {
        const tunes = AnyButton.tunes;

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            onActivate: () => {
                this.data = {
                    "link": this.nodes.linkInput.textContent,
                    "text": this.nodes.textInput.textContent
                }
                this.show(Number(AnyButton.STATE.EDIT))
            }
        }));
    }

    /**
     * Returns true to notify the core that read-only mode is supported
     *
     * @return {boolean}
     */
    static get isReadOnlySupported() {
        return true;
    }
    /**
     *
     * @returns {boolean}
     */
    static get enableLineBreaks() {
        return false;
    }


    /**
     *
     * @returns {{EDIT: number, VIEW: number}}
     * @constructor
     */
    static get STATE() {
        return {
            EDIT: 0,
            VIEW: 1
        };
    }
    /**
     *
     * @param data
     */
    set data(data) {
        this._data = Object.assign({}, {
            link: this.api.sanitizer.clean(data.link || "", AnyButton.sanitize),
            text: this.api.sanitizer.clean(data.text || "", AnyButton.sanitize)
        });
    }
    /**
     *
     * @returns {{text: string, link: string}}
     */
    get data() {
        return this._data;
    }

    /**
     * セーブ時のバリデーション
     * @param savedData
     * @returns {boolean}
     */
    validate(savedData) {
        if (this._data.link === "" || this._data.text === "") {
            return false;
        }

        return true;
    }
    /**
     *
     * @param block
     * @returns {{caption: string, text: string, alignment: string}}
     */
    save(block) {
        return this._data;
    }

    /**
     * タグを全部削除する
     * @returns {{link: boolean, text: boolean}}
     */
    static get sanitize() {
        return {
            text: false,
            link: false
        }
    }

    defaultLinkValidation(text) {
        //全ての文字列が渡されるがURLのみ許可する. URLじゃない文字列も考慮する
        let url = null;
        try {
            url = new URL(text);
        } catch (e) {
            // notifier.show({
            //     message: "URLが間違っています",
            //     style: 'error'
            // })
            return false;
        }
        //httpsかhttpが入っていなければエラー
        if (url.protocol !== "https:" && url.protocol !== "http:") {
            // notifier.show({
            //     message: "正しいURLを入力してください",
            //     style: 'error'
            // })
            return false;
        }
        return true;
    }

    defaultTextValidation(text) {
        if (text === "") {
            // notifier.show({
            //     message: "ボタンのテキストを入力してください",
            //     style: 'error'
            // })
            return false;
        }
        return true;
    }
    /**
     *
     * @param data
     * @param config
     * @param api
     * @param readOnly
     */
    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        this.nodes = {
            wrapper: null,
            container: null,
            inputHolder: null,
            toggleHolder: null,
            anyButtonHolder: null,
            textInput: null,
            linkInput: null,
            registButton: null,
            anyButton: null,
        }
        //css overwrite
        const _CSS = {
            baseClass: this.api.styles.block,
            hide: "hide",
            btn: "anyButton__btn",
            container: "anyButtonContainer",
            input: "anyButtonContainer__input",

            inputHolder: "anyButtonContainer__inputHolder",
            inputText: "anyButtonContainer__input--text",
            inputLink: "anyButtonContainer__input--link",
            registButton: "anyButtonContainer__registerButton",
            anyButtonHolder: "anyButtonContainer__anyButtonHolder",
            btnColor: "anyButton__btn--default",
        }

        this.CSS = Object.assign(_CSS, config.css)
        this.linkValidation = config.linkValidation || this.defaultLinkValidation.bind(this)
        this.textValidation = config.textValidation || this.defaultTextValidation.bind(this)

        this.data = {
            link: "",
            text: ""
        };
        this.data = data;

    }

    render() {
        this.nodes.wrapper = this.make('div', this.CSS.baseClass);
        // this.nodes.container = this.make('div', this.CSS.container); //twitter-embed-tool

        //入力用
        this.nodes.inputHolder = this.makeInputHolder();
        //display button
        this.nodes.anyButtonHolder = this.makeAnyButtonHolder();


        this.nodes.wrapper.appendChild(this.nodes.inputHolder);
        this.nodes.wrapper.appendChild(this.nodes.anyButtonHolder);

        if (this._data.link !== "") {
            this.init()
            this.show(AnyButton.STATE.VIEW)
        }

        // this.nodes.wrapper.appendChild(this.nodes.container);

        return this.nodes.wrapper;
    }


    makeInputHolder() {
        const inputHolder = this.make('div', [this.CSS.inputHolder]);
        this.nodes.textInput = this.make('div', [this.api.styles.input, this.CSS.input, this.CSS.inputText], {
            contentEditable: !this.readOnly,
        });
        this.nodes.textInput.dataset.placeholder = this.api.i18n.t('Button Text');

        this.nodes.linkInput = this.make('div', [this.api.styles.input, this.CSS.input, this.CSS.inputLink], {
            contentEditable: !this.readOnly,
        })
        this.nodes.linkInput.dataset.placeholder = this.api.i18n.t('Link Url');

        this.nodes.registButton = this.make('button', [this.api.styles.button, this.CSS.registButton]);
        this.nodes.registButton.type = 'button';
        this.nodes.registButton.textContent = this.api.i18n.t('Set');


        this.nodes.registButton.addEventListener('click', (event) => {

            if (!this.linkValidation(this.nodes.linkInput.textContent)) {
                return;
            }
            if (!this.textValidation(this.nodes.textInput.textContent)) {
                return;
            }
            this.data = {
                "link": this.nodes.linkInput.textContent,
                "text": this.nodes.textInput.textContent
            }
            this.show(AnyButton.STATE.VIEW);
        });

        inputHolder.appendChild(this.nodes.textInput);
        inputHolder.appendChild(this.nodes.linkInput);
        inputHolder.appendChild(this.nodes.registButton);

        return inputHolder;
    }

    init() {
        this.nodes.textInput.textContent = this._data.text;
        this.nodes.linkInput.textContent = this._data.link;
    }

    show(state) {
        this.nodes.anyButton.textContent = this._data.text;
        this.nodes.anyButton.setAttribute("href", this._data.link);
        this.changeState(state);
    }

    makeAnyButtonHolder() {
        const anyButtonHolder = this.make('div', [this.CSS.hide, this.CSS.anyButtonHolder]);
        this.nodes.anyButton = this.make('a', [this.CSS.btn, this.CSS.btnColor], {
            target: '_blank',
            rel: 'nofollow noindex noreferrer',
        });
        this.nodes.anyButton.textContent = this.api.i18n.t("Default Button");
        anyButtonHolder.appendChild(this.nodes.anyButton);
        return anyButtonHolder;
    }

    changeState(state) {
        switch (state) {
            case AnyButton.STATE.EDIT:
                this.nodes.inputHolder.classList.remove(this.CSS.hide);
                this.nodes.anyButtonHolder.classList.add(this.CSS.hide);

                break;
            case AnyButton.STATE.VIEW:
                this.nodes.inputHolder.classList.add(this.CSS.hide);
                this.nodes.anyButtonHolder.classList.remove(this.CSS.hide);
                break;
        }
    }

    /**
     * node 作成用
     * @param tagName
     * @param classNames
     * @param attributes
     * @returns {*}
     */
    make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName);

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames);
        } else if (classNames) {
            el.classList.add(classNames);
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}