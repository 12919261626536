import { computed, reactive, ref } from 'vue';

import agent from '@/api/agent';
import { getImageUrl } from '@/api/config';
import { Banner, BannerPosition, NewBannerFormValues } from '@/models/banner';
import Pagination, { PagingParams } from '@/models/pagination';

import { formatDateForInput } from './dateUtils';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

interface BannerState {
    banners: Map<string, Banner> | null;
    imagePreview: string | null;
}

export const useBannerStore = defineStore('banner', () => {
    const state = ref<BannerState>({
        banners: new Map(),
        imagePreview: null,
    });
    const tab = reactive({ activeTab: 'main' });
    const bannersPerPage = ref<number>(5);
    const banners = reactive<Map<string, Banner>>(new Map());
    const banner = ref();
    const predicate = ref(new Map().set('position', 'main'));

    const pagination = ref({} as Pagination);
    const pagingParams = ref<PagingParams>(new PagingParams());

    const axiosParams = computed(() => {
        const params = new URLSearchParams();
        params.append('pageNumber', pagingParams.value.pageNumber.toString());
        params.append('pageSize', pagingParams.value.pageSize.toString());
        predicate.value.forEach((value, key) => {
            params.append(key, value.toString());
        });
        return params;
    });

    function setPagingParams(pagingPar: PagingParams) {
        return (pagingParams.value = pagingPar);
    }

    function setPagination(pagi: Pagination) {
        return (pagination.value = pagi);
    }

    function setPredicate(predicateKey: string, value: string) {
        const resetPredicate = () => {
            predicate.value.forEach((_, key) => {
                predicate.value.delete(key);
            });
        };
        switch (predicateKey) {
            case 'main':
                resetPredicate();
                predicate.value.set('position', value);
                break;
            case 'content':
                resetPredicate();
                predicate.value.set('position', value);
                break;
        }
    }

    const bannerForm = ref<NewBannerFormValues>({
        title: '',
        description: '',
        url: '',
        positionIndex: 0,
        image: undefined,
        position: '',
        startDate: '',
        endDate: '',
        imagePreview: null,
        isDraft: false,
        isArchived: false,
    });

    const bannerPositions = ref([
        { value: BannerPosition.main, label: 'Начало' },
        { value: BannerPosition.content, label: 'В съдържание' },
    ]);

    function bannersArray(): Banner[] {
        return Array.from(banners.values());
    }

    const setImagePreview = (url: string) => {
        bannerForm.value.imagePreview = url;
    };

    const getAllBanners = async () => {
        try {
            banners.clear();
            const response = (await agent.Banners.getAllBanners(axiosParams.value)).data;

            response.data.forEach((banner: Banner) => {
                banner.status = banner.isArchived ? 'Архивиран' : 'Публикуван';
                banners.set(banner.id!, banner);
            });

            setPagination(response.pagination);
        } catch (error) {
            console.error('Error fetching banners:', error);
            toast.error('Няма намерени банери!');
        }
    };
    const extensionsToRemove = [
        '.apng',
        '.png',
        '.avif',
        '.gif',
        '.jpg',
        '.jpeg',
        '.jfif',
        '.pjpeg',
        '.pjp',
        '.svg',
        '.webp',
    ];

    const createBanner = async (banner: NewBannerFormValues) => {
        try {
            const formData = new FormData();
            for (const key in banner) {
                if (banner.hasOwnProperty(key)) {
                    const value = (banner as any)[key];
                    if (value !== null && value !== undefined) {
                        if (value instanceof Blob) {
                            const fileName = (value as File).name || 'defaultName.jpg';
                            let processedFileName = fileName;
                            for (const ext of extensionsToRemove) {
                                if (processedFileName.toLowerCase().endsWith(ext)) {
                                    processedFileName = processedFileName.slice(0, -ext.length);
                                    break;
                                }
                            }

                            const file = new File([value], processedFileName, {
                                type: value.type,
                            });
                            formData.append(key, file, processedFileName);
                        } else if (
                            Array.isArray(value) &&
                            value.every(item => item instanceof Blob)
                        ) {
                            const processedFiles: File[] = [];

                            value.forEach((blob, index) => {
                                const fileName = (blob as File).name || `defaultName_${index}.jpg`;
                                let processedFileName = fileName;
                                for (const ext of extensionsToRemove) {
                                    if (processedFileName.toLowerCase().endsWith(ext)) {
                                        processedFileName = processedFileName.slice(0, -ext.length);
                                        break;
                                    }
                                }

                                const file = new File([blob], processedFileName, {
                                    type: blob.type,
                                });
                                processedFiles.push(file);
                            });
                            processedFiles.forEach((file, index) => {
                                formData.append(`${key}`, file, file.name);
                            });
                        } else {
                            if (typeof value === 'object') {
                                formData.append(key, JSON.stringify(value));
                            } else {
                                formData.append(key, value);
                            }
                        }
                    }
                }
            }
            const response = await agent.Banners.createBanner(formData);
            banners.set(response.data.id, response.data);
            state.value.banners?.set(response.data.id, response.data);
        } catch (error) {
            console.error('Error creating banner:', error);
        }
    };

    const updateBanner = async (id: string, banner: NewBannerFormValues) => {
        try {
            const formData = new FormData();
            for (const key in banner) {
                if (banner.hasOwnProperty(key)) {
                    const value = (banner as any)[key];
                    if (value !== null && value !== undefined) {
                        if (value instanceof Blob) {
                            const fileName = (value as File).name || 'defaultName.jpg';
                            let processedFileName = fileName;
                            for (const ext of extensionsToRemove) {
                                if (processedFileName.toLowerCase().endsWith(ext)) {
                                    processedFileName = processedFileName.slice(0, -ext.length);
                                    break;
                                }
                            }

                            const file = new File([value], processedFileName, {
                                type: value.type,
                            });
                            formData.append(key, file, processedFileName);
                        } else if (
                            Array.isArray(value) &&
                            value.every(item => item instanceof Blob)
                        ) {
                            const processedFiles: File[] = [];

                            value.forEach((blob, index) => {
                                const fileName = (blob as File).name || `defaultName_${index}.jpg`;
                                let processedFileName = fileName;
                                for (const ext of extensionsToRemove) {
                                    if (processedFileName.toLowerCase().endsWith(ext)) {
                                        processedFileName = processedFileName.slice(0, -ext.length);
                                        break;
                                    }
                                }

                                const file = new File([blob], processedFileName, {
                                    type: blob.type,
                                });
                                processedFiles.push(file);
                            });
                            processedFiles.forEach((file, index) => {
                                formData.append(`${key}`, file, file.name);
                            });
                        } else if (value === null) {
                            formData.append(key, ''); // send empty string for null values
                        } else {
                            if (typeof value === 'object') {
                                formData.append(key, JSON.stringify(value));
                            } else {
                                if (key === 'positionIndex' && value > 0) {
                                    formData.append('isArchived', banner.isArchived.toString());
                                    formData.append('positionIndex', value);
                                } else {
                                    if (key === 'isArchived') continue;
                                    formData.append(key, value);
                                }
                            }
                        }
                    }
                }
            }



            await agent.Banners.updateBanner(id, formData);
            // banners.set(response.data.id, response.data);
        } catch (error) {
            console.error('Error updating banner:', error);
        }
    };

    const deleteBanner = async (id: string) => {
        try {
            const response = await agent.Banners.deleteBanner(id);
            state.value.banners?.set(response.data.id, response.data);
            await getAllBanners();
            toast.success('Банерът е изтрит успешно!');
        } catch (error) {
            console.error('Error deleting banner:', error);
            toast.error(`Грешка при изтриване на банер! ${error}`);
        }
    };

    const getBanner = async (id: string) => {
        const bannerData = (await agent.Banners.getBanner(id)).data;

        if (!bannerData) return;
        const response = await fetch(getImageUrl(bannerData.image));

        const blob = await response.blob();
        const file = new File([blob], bannerData.image, {
            type: blob.type,
        });

        bannerForm.value = {
            ...bannerData,
            imagePreview: getImageUrl(bannerData.image),
            image: file, startDate: formatDateForInput(bannerData.startDate),
            endDate: formatDateForInput(bannerData.endDate)
        };
    };

    const resetForm = () => {
        bannerForm.value = {
            title: '',
            description: '',
            url: '',
            positionIndex: 0,
            image: undefined,
            position: '',
            startDate: '',
            endDate: '',
            imagePreview: null,
            isDraft: false,
            isArchived: false,
        };
    };

    const getBannerIndexes = async () => {
        try {
            const response = await agent.Banners.getIndexes();
            return response.data;
        } catch (error) {
            console.error('Error fetching banner indexes:', error);
        }
    };

    return {
        state,
        banners,
        banner,
        bannerForm,
        bannerPositions,
        pagination,
        pagingParams,
        setPagingParams,
        bannersArray,
        getAllBanners,
        createBanner,
        updateBanner,
        deleteBanner,
        setImagePreview,
        getBanner,
        resetForm,
        getBannerIndexes,
        setPredicate,
        tab,
        bannersPerPage,
    };
});
