export default class CustomEmbed {
  static get toolbox() {
    return {
      title: 'Embed',
      icon: '🌐',
    };
  }

  constructor({ data }) {
    this.data = data || {};
    this.wrapper = document.createElement('div');
  }

  render() {
    this.wrapper.innerHTML = `
      <input type="text" placeholder="Enter any URL..." value="${this.data.url || ''}" style="width: 100%; padding: 8px;"/>
      <div class="embed-preview" style="margin-top:10px;">${this.getEmbedHTML(this.data.url)}</div>
    `;

    const input = this.wrapper.querySelector('input');
    const preview = this.wrapper.querySelector('.embed-preview');

    input.addEventListener('input', () => {
      this.data = { url: input.value.trim() }; // Update data correctly
      preview.innerHTML = this.getEmbedHTML(this.data.url);
    });

    return this.wrapper;
  }

  getEmbedHTML(url) {
    return url
      ? `<iframe src="${url}" style="width:100%; height:300px; border: none;"></iframe>`
      : '';
  }

  save() {
    return this.data;
  }
}
