import { computed } from 'vue';

import { CategoryModel } from '@/models/category';
import { useCategoryStore } from '@/stores/categoryStore';
import { useUserStore } from '@/stores/userStore';

import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', () => {
  const categoryStore = useCategoryStore();
  const userStore = useUserStore();
  // level and parentName are not required, they are used for recursion
  const generateIndentedCategories = (categories: CategoryModel[], level = 0, parentName?: string): { id: number; name: string }[] => {
    return categories.reduce<{ id: number; name: string }[]>((acc, category) => {
      acc.push({
        id: category.id,
        name: `${'—'.repeat(level)} ${category.bgname} ${parentName ? `(${parentName})` : ''}`,
      });
      if (category.children && category.children.length > 0) {
        acc.push(...generateIndentedCategories(category.children, level + 1, category.bgname));
      }
      return acc;
    }, []);
  };

  function getStatus(isDraft: boolean, isDeleted: boolean) {
    if (isDeleted) return 'Архивиран';
    return isDraft ? 'Чернова' : 'Публикуван';
  }

  const allowedUserCategories = computed(() => {
    if (!userStore.user || !categoryStore.categoriesArray.length) {
      return [];
    }
    const isAdminOrMasterAdmin = userStore.user!.role.name === 'administrator' || userStore.user!.role.name === 'master_admin';

    // Return all categories for admin/master_admin
    if (isAdminOrMasterAdmin) {
      return generateIndentedCategories(categoryStore.categoriesArray);
    }
    return generateIndentedCategories(categoryStore.categoriesArray)
      .filter(category => {
        const categoryId = category.id;
        const userCategories = userStore.user!.categories?.includes(categoryId);
        return userCategories;
      })
      .map(category => ({
        id: category.id,
        name: category.name,
      }));
  });

  return {
    generateIndentedCategories,
    getStatus,
    allowedUserCategories,
  };
});
