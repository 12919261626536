export enum BannerPosition {
    main = 'main',
    content = 'content',
}

export interface Banner {
    id: string;
    title: string;
    description: string;
    url: string;
    positionIndex: number;
    position: BannerPosition;
    image: string;
    startDate: string;
    endDate: string;
    isArchived: boolean;
    isDraft: boolean;
    createdAt: string;
    updatedAt: string;
    status: string;
}

export interface NewBannerFormValues {
    title: string;
    description: string;
    url: string;
    positionIndex: number;
    image: File | File[] | null | undefined;
    position: BannerPosition | string;
    startDate: string;
    endDate: string;
    imagePreview: string | null;
    isDraft: boolean;
    isArchived: boolean;
}
