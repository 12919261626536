<template>
    <RouterView></RouterView>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import { useUserStore } from '@/stores/userStore';

import { setupAxiosInterceptors } from './api/agent';
import { toast } from 'vue3-toastify';
import { RouterView } from 'vue-router';

const userStore = useUserStore();
onMounted(() => {
    setupAxiosInterceptors(() => {
        userStore.logout();
        toast.error('Сесията изтече, моля влезте отново!');
    });
    userStore.hydrate();
});
</script>
