// import ArticleCreate from '@/components/articles/dashboard/ArticleCreate.vue';
// import ArticleUpdate from '@/components/articles/dashboard/ArticleUpdate.vue';
// import BannerCreate from '@/components/banners/BannerCreate.vue';
// import BannerEdit from '@/components/banners/BannerEdit.vue';

// import Cookies from 'js-cookie';
// // Importing Cookies to check authentication
// import { createRouter, createWebHistory } from 'vue-router';
// import { useUserStore } from '@/stores/userStore';

// const routes = [
//     {
//         path: '/:pathMatch(.*)*',
//         component: () => import('@/views/auth/Error.vue'),
//         meta: { roles: ['master_admin', 'administrator'] },
//     },
//     {
//         path: '/',
//         meta: {
//             requiresAuth: true,
//             roles: ['master_admin', 'administrator'],
//         },
//         component: () => import('@/layouts/full/FullLayout.vue'),
//         children: [
//             {
//                 name: 'Начало',
//                 path: '/',
//                 redirect: '/articles',
//             },
//             {
//                 name: 'Потребители',
//                 path: '/users',
//                 meta: { roles: ['master_admin', 'administrator'] },
//                 component: () => import('@/views/components/users/Users.vue'),
//             },
//             {
//                 name: 'categories',
//                 path: '/categories',
//                 meta: { roles: ['master_admin', 'administrator', 'manager'] },
//                 component: () => import('@/views/components/categories/Categories.vue'),
//             },
//             {
//                 name: 'banners',
//                 path: '/banners',
//                 component: () => import('@/views/components/banners/Banners.vue'),
//             },
//             {
//                 name: 'BannerCreate',
//                 path: '/banners/create',
//                 component: BannerCreate,
//             },
//             {
//                 name: 'BannerEdit',
//                 path: '/banners/edit/:id',
//                 component: BannerEdit,
//             },
//             {
//                 name: 'articles',
//                 path: '/articles',
//                 meta: { roles: ['master_admin', 'administrator', 'manager'] },
//                 component: () => import('@/views/articles/Articles.vue'),
//             },
//             {
//                 path: 'articles/create',
//                 name: 'ArticleCreate',
//                 component: ArticleCreate,
//             },
//             {
//                 path: 'articles/edit/:id',
//                 name: 'ArticleUpdate',
//                 component: ArticleUpdate,
//             },
//             {
//                 name: 'Icons',
//                 path: '/icons',
//                 component: () => import('@/views/pages/Icons.vue'),
//             },
//             {
//                 name: 'Starter',
//                 path: '/sample-page',
//                 component: () => import('@/views/pages/SamplePage.vue'),
//             },
//             {
//                 name: 'Departments',
//                 path: '/department',
//                 component: () => import('@/views/components/departments/Departments.vue'),
//             },
//             {
//                 name: 'Employees',
//                 path: '/employees',
//                 component: () => import('@/views/components/employees/Employees.vue'),
//             },
//             {
//                 name: 'UserProfile',
//                 path: '/profile',
//                 component: () => import('@/components/users/profile/UserProfile.vue'),
//             },
//             {
//                 name: 'Events',
//                 path: '/events',
//                 component: () => import('@/views/components/events/Events.vue'),
//             },
//         ],
//     },
//     {
//         path: '/auth',
//         component: () => import('@/layouts/blank/BlankLayout.vue'),
//         meta: {
//             requiresAuth: false,
//         },
//         children: [
//             {
//                 name: 'Login',
//                 path: '/login',
//                 component: () => import('@/views/pages/Login.vue'),
//             },
//         ],
//     },
// ];

// export const router = createRouter({
//     history: createWebHistory(import.meta.env.BASE_URL),
//     routes,
// });

// router.beforeEach((to, from, next) => {
//   const userStore = useUserStore();

//   if (!userStore.user && to.meta.requiresAuth) {
//     next('/login');
//   } else if (
//     to.meta?.roles &&
//     !to.meta.roles.includes(userStore.user?.role.name)
//   ) {
//     next('/login');
//   } else {
//     next();
//   }
// });
// export default router;


import ArticleCreate from '@/components/articles/dashboard/ArticleCreate.vue';
import ArticleUpdate from '@/components/articles/dashboard/ArticleUpdate.vue';
import BannerCreate from '@/components/banners/BannerCreate.vue';
import BannerEdit from '@/components/banners/BannerEdit.vue';

import Cookies from 'js-cookie';
// Importing Cookies to check authentication
import { createRouter, createWebHistory, RouteMeta } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { toast } from 'vue3-toastify';

declare module 'vue-router' {
    interface RouteMeta {
        roles?: string[];
        requiresAuth?: boolean;
    }
}

const routes = [
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/auth/Error.vue'),
        meta: { roles: ['master_admin', 'administrator'] },
    },
    {
        path: '/',
        meta: {
            requiresAuth: true,
            roles: ['master_admin', 'administrator'],
        },
        component: () => import('@/layouts/full/FullLayout.vue'),
        children: [
            {
                name: 'Начало',
                path: '/',
                redirect: '/articles',
            },
            {
                name: 'Потребители',
                path: '/users',
                meta: { roles: ['master_admin', 'administrator'] },
                component: () => import('@/views/components/users/Users.vue'),
            },
            {
                name: 'categories',
                path: '/categories',
                meta: { roles: ['master_admin', 'administrator', 'manager'] },
                component: () => import('@/views/components/categories/Categories.vue'),
            },
            {
                name: 'banners',
                path: '/banners',
                component: () => import('@/views/components/banners/Banners.vue'),
            },
            {
                name: 'BannerCreate',
                path: '/banners/create',
                component: BannerCreate,
            },
            {
                name: 'BannerEdit',
                path: '/banners/edit/:id',
                component: BannerEdit,
            },
            {
                name: 'articles',
                path: '/articles',
                meta: { roles: ['master_admin', 'administrator', 'manager'] },
                component: () => import('@/views/articles/Articles.vue'),
            },
            {
                path: 'articles/create',
                name: 'ArticleCreate',
                meta: { roles: ['master_admin', 'administrator', 'manager'] },
                component: ArticleCreate,
            },
            {
                path: 'articles/edit/:id',
                name: 'ArticleUpdate',
                meta: { roles: ['master_admin', 'administrator', 'manager'] },
                component: ArticleUpdate,
            },
            {
                path: 'articles/order',
                name: 'ArticleOrdering',
                meta: { roles: ['master_admin', 'administrator', 'manager'] },
                component: () => import('@/components/articles/dashboard/ArticleOrdering.vue'),
            },
            {
                name: 'Icons',
                path: '/icons',
                component: () => import('@/views/pages/Icons.vue'),
            },
            {
                name: 'Starter',
                path: '/sample-page',
                component: () => import('@/views/pages/SamplePage.vue'),
            },
            {
                name: 'Departments',
                path: '/department',
                component: () => import('@/views/components/departments/Departments.vue'),
            },
            {
                name: 'Employees',
                path: '/employees',
                component: () => import('@/views/components/employees/Employees.vue'),
            },
            {
                name: 'UserProfile',
                path: '/profile',
                component: () => import('@/components/users/profile/UserProfile.vue'),
            },
            {
                name: 'Events',
                path: '/events',
                component: () => import('@/views/components/events/Events.vue'),
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('@/layouts/blank/BlankLayout.vue'),
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                name: 'Login',
                path: '/login',
                component: () => import('@/views/pages/Login.vue'),
            },
        ],
    },
];

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();

    if (!userStore.user && to.meta.requiresAuth) {
        next('/login');
    } else if (
        to.meta?.roles &&
        (!userStore.user || !to.meta.roles.includes(userStore.user.role.name))
    ) {
        toast.error('Нямате права за достъп до тази страница');
        next(from.path);
    } else {
        next();
    }
});
export default router;